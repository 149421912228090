exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1g2_9{padding-left:20px;padding-right:20px}._3n8Tt{margin:0 auto;padding:100px 0 40px;max-width:1000px}", ""]);

// exports
exports.locals = {
	"root": "_1g2_9",
	"container": "_3n8Tt"
};